import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    let namePart = value.split('@')[0];
    let nameParts = namePart.split('.');
    let formattedName = nameParts.map(part => {
      return part.charAt(0).toUpperCase() + part.slice(1);
    }).join(' ');
    
    return formattedName;
  }
}
