import { NgModule } from '@angular/core';
import { SharePricePipe } from './share-price.pipe';
import { FormatNamePipe } from './format-name.pipe';

@NgModule({
    declarations: [
        SharePricePipe,
        FormatNamePipe
    ],
    exports: [
        SharePricePipe,
        FormatNamePipe
    ]
})
export class PipesModule {

}
