import { Component, NgModule, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InvestorsService } from '../../services/investors.service';
import { from, fromEvent, Subscription, timer } from 'rxjs';
import { IInvestorLookupApiResponse, IContactPortfolioLookupApiResponse, IPortfolioSubscriptionLookupApiResponse } from '../../models/investor/IInvestorLookupApiResponse';
import { FormGroupTyped } from 'typed-reactive-forms';
import { debounce, filter, mergeMap } from 'rxjs/operators';
import { Problem } from '../../models/Problem';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from '../../pipes/pipes.module';
import * as $ from "jquery";
import { SearchBy } from '../../enums/search-by-enum';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-investor-crm-search',
  templateUrl: './investor-crm-search.component.html',
  styleUrls: ['./investor-crm-search.component.scss'],
})
export class InvestorCrmSearchComponent implements OnDestroy {

  @Input() investorSearchFormId = "investor-search-form";
  @Input() searchResultDisplayLimit = 30;
  @Input() selectionHidden = false;
  @Input() searchByAdviser: boolean;
  @Output() searchSelection = new EventEmitter<IInvestorLookupApiResponse>();

  investorArray: any[] = [];
  portfoliosArray: any[] = [];
  investorSearchForm = this.initInvestorSearchInputForm();
  subscriptions$: Subscription[] = [];
  investorSearchResults: IInvestorLookupApiResponse[] = [];
  errorMessage = "";
  showSearchResults = false;
  searchInProgress = false;
  noResultsFound = false;
  responsemessage: string = "";
  searchBy: any;
  manuallySet = false;

  constructor(private fb: FormBuilder, private investorService: InvestorsService) {
    this.subscriptions$.push(this.setupAutoSearchSubscription(), this.windowEventsSubscription());
  }

  public initInvestorSearchInputForm(): FormGroupTyped<IInvestorSearchForm> {
    this.searchBy = this.populateSearchBy(this.searchByAdviser);

    return this.fb.group({
      searchBy: [SearchBy.Investor, Validators.required],
      searchInput: ['', Validators.required]
    }) as FormGroupTyped<IInvestorSearchForm>;
  }

  public searchInvestor(e?: Event) {

    if (this.searchInProgress || this.manuallySet) {
      this.manuallySet = false;
      return;
    }

    const searchQuery = this.investorSearchForm.controls.searchInput.value.trim();
    this.searchInProgress = false
    if (searchQuery.length >= 3) {
      debounce(() => timer(56))
      this.searchInProgress = true;
    }
    else {
      return
    }
    this.searchInProgress = true;

    if (this.containsOnlySpecialChars(searchQuery) || this.numberFollowedBySpecialChar(searchQuery) || this.alphaFollowedBySpecialChar(searchQuery)) {
      this.responsemessage = "(zero) investor matches";
      this.noResultsFound = true;
      this.searchInProgress = false;
      return;
    }

    let searchBy: SearchBy;
    if (this.searchByAdviser) {
      searchBy = SearchBy.Adviser;
      this.investorSearchForm.controls.searchBy.setValue(SearchBy.Adviser);
    } else {
      searchBy = this.investorSearchForm.controls.searchBy.value;
    }
    this.investorService.searchInvestors(searchBy, this.investorSearchForm.controls.searchInput.value).subscribe((result: IInvestorLookupApiResponse[]) => {

      if (searchBy == SearchBy.SubscriptionRef) {
        this.populateInvestorSearchResultsForSubscriptions(result);
      }
      else {
        this.investorSearchResults = result || [];
      }

      if (this.investorSearchResults.length != 0) {
        this.populateDisplayName(searchQuery);

        this.showSearchResults = true;
        this.noResultsFound = false;
        this.errorMessage = "";
      } else {
        this.responsemessage = "There are no matches on your search.";
        this.noResultsFound = true;
      }
    },
      (errorResponse: Problem) => {
        this.searchInProgress = false;
        this.noResultsFound = false;

        this.errorMessage = "The search has errored. Please try again.";
      },
      () => {
        this.searchInProgress = false;
        this.errorMessage = "";
      });
  };

  private populateDisplayName(searchQuery: string) {
    let regexPattern = /\s+/g;
    let searchItem = searchQuery.replace(regexPattern, " ").trim();

    this.investorSearchResults.forEach((investor, i) => {
      switch (+this.investorSearchForm.controls.searchBy.value) {
        case SearchBy.Investor:
          investor.searchBy = SearchBy.Investor;
          if (investor.shareholderId && investor.shareholderId.includes(searchItem)) {
            investor.displayName = this.makeBold(investor.shareholderId, searchItem) + ' - ' + investor.legalName;
          }
          else {
            investor.displayName = investor.shareholderId + ' - ' + this.makeBold(investor.legalName, searchItem);
          }
          break;
        case SearchBy.PortfolioRef:
          investor.searchBy = SearchBy.PortfolioRef;
          let portfolioRef = investor.portfolios[0].reference;
          investor.displayName = this.makeBold(portfolioRef, searchItem) + ' - ' + investor.shareholderId + ' - ' + this.makeBold(investor.legalName.replace(regexPattern, " ").trim(), searchItem.trim());
          break;
        case SearchBy.SubscriptionRef:
          investor.searchBy = SearchBy.SubscriptionRef;
          let subscriptionRef = investor.portfolios[0].subscriptions[0].subscriptionReference;
          investor.displayName = this.makeBold(subscriptionRef, searchItem) + ' - ' + investor.shareholderId + ' - ' + this.makeBold(investor.legalName.replace(regexPattern, " ").trim(), searchItem.trim());
          break;
        case SearchBy.Adviser:
          investor.searchBy = SearchBy.Adviser;
          investor.displayName = this.makeBold(investor.legalName, searchItem);
          break;
      }
    });
  }

  private makeBold(input: string, wordsToBold: string) {
    const searchRegex = new RegExp(wordsToBold, 'gi');
    let wrdMatch = RegExp(searchRegex).exec(input);
    return input.replace(new RegExp(`(${wrdMatch})`, 'gi'), '<b>$1</b>');
  }

  public onSearchItemSelection(value: IInvestorLookupApiResponse) {
    this.investorSearchForm.controls.searchInput.setValue(value.legalName);
    this.searchSelection.emit(value);
    this.investorSearchResults = [];
    this.manuallySet = true;
  }

  public windowEventsSubscription() {
    const events = ['resize', 'click'];
    return from(events)
      .pipe(
        mergeMap(event => fromEvent(window, event))
      )
      .pipe(filter(() => !this.searchInProgress))
      .pipe(debounce(() => timer(250)))
      .subscribe((event) => {
        switch (event.type) {
          case "click":
            if (!(
              $(event.target as any).parent(`#${this.investorSearchFormId}`).length ||
              $(event.target as any).attr('id') === this.investorSearchFormId
            )) {
              this.clearSearchResults();
            }
          case "resize":
            this.clearSearchResults();
        }
      });
  }

  setupAutoSearchSubscription() {
    return this.investorSearchForm.controls.searchInput.valueChanges
      .pipe(debounce(() => timer(1000)))
      .pipe(filter((x) => this.investorSearchForm.controls.searchInput.valid && !!this.investorSearchForm.controls.searchInput.value.trim()))
      .subscribe(() => this.searchInvestor());
  }

  clearSearchResults() {
    this.investorSearchResults = [];
    this.showSearchResults = false;
    setTimeout(() => this.noResultsFound = false, 300);
  }

  private containsOnlySpecialChars(value: string): boolean {
    let spclCharRegEx = RegExp(/^[^a-zA-Z0-9]+$/);
    return spclCharRegEx.test(value);
  }

  private numberFollowedBySpecialChar(value: string): boolean {
    const numCharRegex = RegExp(/^\d*[0-9][^0-9A-Za-z]/);
    return numCharRegex.test(value);

  }

  private alphaFollowedBySpecialChar(value: string): boolean {
    const alphSpclCharRegex = RegExp(/^\d*[A-Za-z]+[^A-Za-z0-9-\s]/);
    return alphSpclCharRegex.test(value);

  }

  private populateSearchBy(includeAdviser: boolean) {
    let enumValues: { id: number; name: string }[] = [];
    for (let n in SearchBy) {
      if (typeof SearchBy[n] === 'number') {
        let split = n.split(/(?=[A-Z])/);
        if (includeAdviser || (!includeAdviser && SearchBy[n].toString() !== SearchBy.Adviser.toString())) {
          enumValues.push({ id: <any>SearchBy[n], name: `Search by ${split.join(" ")}` });
        }
      }
    }

    return enumValues;
  }

  private populateInvestorSearchResultsForSubscriptions(result: IInvestorLookupApiResponse[]) {
    result.forEach(investor => {
      investor.portfolios.forEach(portfolio => {
        portfolio.subscriptions.forEach(subscription => {
          let newInvestor = { ...investor };
          let newPortfolio = { ...portfolio };
          let newSubscription = { ...subscription };
          newPortfolio.subscriptions = [newSubscription];
          newInvestor.portfolios = [newPortfolio];
          this.investorSearchResults.push(newInvestor);
        });
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((subscription) => subscription.unsubscribe());
  }
}

@NgModule({
  declarations: [InvestorCrmSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    PipesModule,
    ReactiveFormsModule
  ],
  exports: [CommonModule, InvestorCrmSearchComponent]
})
export class InvestorCrmSearchModule { }

interface IInvestorSearchForm {
  searchBy: SearchBy;
  searchInput: string;
}
